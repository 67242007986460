import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import _ from 'lodash';
import deepCopy from '../../../../utilities/deepCopy';
import filterRows from '../../../../helpers/shared/filterRows';
import PageError from '../../../errors/PageError';
import * as sorting from '../../../../constants/sorting';
import { markCasesNotChallenged, resetMarkCasesNotChallenged } from '../../../../containers/Representments/opportunitiesSlice';
import EnhancedTableHead from './EnhancedTableHead';
import searchableColumns from '../constants/searchableColumns';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import CurrentInventoryTableRow from './CurrentInventoryTableRow';
import { downloadToCsv } from '../../../../utilities/downloadToCsv';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import ErrorIcon from '@mui/icons-material/Error';

export const CurrentInventoryTable = (props) => {
	const {
		t,
		data,
		isLoading,
		hasBeenLoaded,
		error,
		markCasesNotChallenged,
		successfullyCompleted,
		markNotChallengedError
	} = props;

	const [selected, setSelected] = React.useState([]);
	const [sortOrder, setSortOrder] = React.useState(sorting.sortOrder.asc);
	const [orderBy, setOrderBy] = React.useState('chargebackDate');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(40);
	const [searchTerm, setSearchTerm] = React.useState('');

	const location = useLocation();

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = data.map((n) => n.chargebackId);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleSortRequest = (event, property) => {
		if (property !== orderBy) {
			setSortOrder(sorting.sortOrder.asc);
			setOrderBy(property);
		} else {
			setSortOrder(sortOrder === sorting.sortOrder.asc ? sorting.sortOrder.desc : sorting.sortOrder.asc);
		}
	};

	const handleRowClick = (event, key) => {
		const selectedIndex = selected.indexOf(key);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, key);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClearSearchTerm = () => {
		setSearchTerm('');
	};

	const handleMarkedNotChallenged = () => {
		const { startDate, endDate, processorId } = queryString.parse(
			location.search
		);

		const casesToBeMarked = deepCopy(selected);

		markCasesNotChallenged({
			notChallengedOn: dayjs().format(),
			chargebackIdList: casesToBeMarked,
			startDate: startDate || dayjs().add(-7, 'days').format('YYYY-MM-DD'),
			endDate: endDate || dayjs().format('YYYY-MM-DD'),
			processorId
		});
	};

	React.useEffect(() => {
		if (successfullyCompleted) {
			setSelected([]);
			resetMarkCasesNotChallenged();
		} else if (markNotChallengedError) {
			setSelected([]);
		}
	}, [successfullyCompleted, markNotChallengedError]);

	const handleDownloadToCsv = () => {
		const downloadEntries =
			selected.length > 0
				? data.filter((item) => {
					return selected.includes(item.chargebackId);
				}) : filterRows(data, searchTerm);

		const mappedEntries = downloadEntries?.map((entry) => {
			return {
				caseNumber: entry.caseId,
				billingLastName: entry.billingLastName,
				amount: entry.amount,
				cardBrand: entry.paymentCardBrand,
				lastCardFour: entry.paymentCardLast4,
				billingDescriptor: entry.billingDescriptor,
				chargebackDate: entry.receivedByMerchantOn,
				reasonCode: entry.code,
				expiration: entry.dueDate
			};
		});

		downloadToCsv(mappedEntries, 'current_inventory');
	};

	const isSelected = (key) => selected.indexOf(key) !== -1;

	const preparedData = hasBeenLoaded
		? _.orderBy(filterRows(data, searchTerm, searchableColumns), [orderBy], [sortOrder]) : [];

	if (error) {
		return (
			<PageError errorText={t('representments:representmentsTable.error')} />
		);
	}

	return (
		<Box sx={{ width: '100%', minWidth: 500 }} my={2} pr={3} elevation={1} component={Paper}>
			{ markNotChallengedError
				? (
					<Stack direction='row' spacing={2} justifyContent='center' my={2}>
						<ErrorIcon color='error' />
						<Typography variant='overline'>{t('representments:failedToMarkNotChallenged')}</Typography>
					</Stack>
				) : null
			}
			<EnhancedTableToolbar
				numSelected={selected.length}
				handleMarkedNotChallenged={handleMarkedNotChallenged}
				downloadToCsv={handleDownloadToCsv}
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				onClearSearchTerm={handleClearSearchTerm}
			/>
			<TableContainer >
				<Table
					sx={{ maxHeight: 400 }}
					aria-labelledby='tableTitle'
					size={'small'}
					stickyHeader
				>
					<EnhancedTableHead
						numSelected={selected.length}
						order={sortOrder}
						orderBy={orderBy}
						onSelectAllClick={handleSelectAllClick}
						handleSortRequest={handleSortRequest}
						rowCount={data.length}
					/>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={13}>
									<LinearProgress width={'100%'} />
								</TableCell>
							</TableRow>
						) : null}
						{hasBeenLoaded
							? preparedData
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row.chargebackId);
									const labelId = `current-iventory-table-checkbox-${index}`;

									return (
										<CurrentInventoryTableRow
											key={index}
											row={row}
											isItemSelected={isItemSelected}
											handleRowClick={handleRowClick}
											labelId={labelId}
										/>
									);
								})
							: null}
						{ preparedData.length === 0 && hasBeenLoaded
							? (
								<TableRow>
									<TableCell colSpan={13}>
										<Stack direction='row' justifyContent='center'>
											<Typography>{t('common:noResults')}</Typography>
										</Stack>
									</TableCell>
								</TableRow>
							) : null
						}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 40, 60]}
				component='div'
				count={preparedData?.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>
	);
};

CurrentInventoryTable.propTypes = {
	t: PropTypes.func,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	markCasesNotChallenged: PropTypes.func,
	resetMarkCasesNotChallenged: PropTypes.func,
	successfullyCompleted: PropTypes.bool,
	markNotChallengedError: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.opportunitiesSlice.opportunities.isLoading,
		hasBeenLoaded: state.opportunitiesSlice.opportunities.hasBeenLoaded,
		error: state.opportunitiesSlice.opportunities.error,
		data: state.opportunitiesSlice.opportunities.data,
		successfullyCompleted: state.opportunitiesSlice.markNotChallenged.successfullyCompleted,
		markNotChallengedError: state.opportunitiesSlice.markNotChallenged.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		markCasesNotChallenged: (payload) => {
			dispatch(markCasesNotChallenged(payload));
		},
		resetMarkCasesNotChallenged: (payload) => {
			dispatch(resetMarkCasesNotChallenged(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['representments', 'common'])(CurrentInventoryTable));
