import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { rulesActions } from '../../../containers/Rules';
import deepCopy from '../../../utilities/deepCopy';
import ActionRow from '../shared/ActionRow';
import SubmitRow from './components/SubmitRow';
import newRuleConfiguration from '../constants/newRuleConfiguration';
import mapConfigurationForSubmit from '../shared/mappers/mapConfigurationForSubmit';
import ErrorDisplay from '../shared/ErrorDisplay';

// Stages
import RuleBuilder from '../shared/stages/RuleBuilder';
import BuildListSelect from '../shared/stages/BuildListSelect';
import NotificationSettingsSelect from '../shared/stages/NotificationSettings/NotificationSettingsSelect';
import RulePropertiesSelect from '../shared/stages/RulePropertiesSelect';

import Stack from '@mui/material/Stack';

const BuildRule = (props) => {
	const {
		isCreatingRule,
		creationSuccess,
		creationError,
		createRule,
		categoryOptionsError,
		builderOptionsError,
		resetRuleCreate,
		categoryOptionsAreLoading,
		builderOptionsAreLoading,
		user
	} = props;

	const [ruleConfiguration, setRuleConfiguration] = React.useState(
		deepCopy(newRuleConfiguration)
	);
	const [submitErrors, setSubmitErrors] = React.useState();

	const navigate = useNavigate();

	React.useEffect(() => {
		if (submitErrors?.length === 0) {
			setSubmitErrors();
		}
	}, [submitErrors]);

	React.useEffect(() => {
		if (creationSuccess) {
			resetRuleCreate();
			navigate('/rules');
		}
	}, [creationSuccess]);

	const onClickSubmit = () => {
		const newRuleConfiguration = mapConfigurationForSubmit(
			user,
			ruleConfiguration
		);

		createRule({
			newRuleConfiguration
		});
	};

	const disabledBuilder =
		categoryOptionsError !== undefined || builderOptionsError !== undefined;

	return (
		<Stack p={3} spacing={3}>
			<ActionRow
				isLoading={isCreatingRule}
				actionSuccess={creationSuccess}
				actionError={creationError}
			/>
			<ErrorDisplay submitErrors={submitErrors} />
			<RuleBuilder
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<BuildListSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<NotificationSettingsSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<RulePropertiesSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<SubmitRow
				ruleConfiguration={ruleConfiguration}
				onClickSubmit={onClickSubmit}
				isCreatingRule={isCreatingRule}
				creationError={creationError}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
				isLoading={categoryOptionsAreLoading || builderOptionsAreLoading}
			/>
		</Stack>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		isCreatingRule: state.rulesReducer.getIn(['createRule', 'isCreatingRule']),
		creationSuccess: state.rulesReducer.getIn(['createRule', 'success']),
		creationError: state.rulesReducer.getIn(['createRule', 'error']),
		categoryOptionsAreLoading: state.rulesReducer.getIn([
			'builderCategories',
			'isLoading'
		]),
		categoryOptionsError: state.rulesReducer.getIn([
			'builderCategories',
			'error'
		]),
		builderOptionsAreLoading: state.rulesReducer.getIn([
			'builderOptions',
			'isLoading'
		]),
		builderOptionsError: state.rulesReducer.getIn(['builderOptions', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createRule: (payload) => {
			dispatch(rulesActions.createRule(payload));
		},
		resetRuleCreate: (payload) => {
			dispatch(rulesActions.resetRuleCreate(payload));
		}
	};
};

BuildRule.propTypes = {
	isCreatingRule: PropTypes.bool,
	creationSuccess: PropTypes.bool,
	creationError: PropTypes.string,
	createRule: PropTypes.func,
	categoryOptionsError: PropTypes.string,
	builderOptionsError: PropTypes.string,
	resetRuleCreate: PropTypes.func,
	categoryOptionsAreLoading: PropTypes.bool,
	builderOptionsAreLoading: PropTypes.bool,
	user: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildRule);
