import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import getSubmitErrors from '../../shared/validators/getSubmitErrors';
import ContainedLoadingButton from '../../../buttons/ContainedLoadingButton';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const SubmitRow = (props) => {
	const {
		t,
		ruleConfiguration,
		onClickSubmit,
		isCreatingRule,
		creationError,
		submitErrors,
		setSubmitErrors,
		isLoading
	} = props;

	const onSubmitClicked = () => {
		const submitErrors = getSubmitErrors(ruleConfiguration, t);

		if (submitErrors.length > 0) {
			setSubmitErrors(submitErrors);
		} else {
			setSubmitErrors();
			onClickSubmit();
		}
	};

	return (
		<Stack
			direction='row'
			justifyContent='flex-end'
			alignItems='center'
			p={1}
			sx={{ alignSelf: 'center', width: '100%', maxWidth: '900px' }}
		>
			<Box sx={{ flex: 1 }}>
				{creationError ? (
					<Stack>
						<Typography color='error' sx={{ fontWeight: 600 }}>
							{t('rules:submitRow.failureMessage')}
						</Typography>
						<Typography variant='caption'>{creationError}</Typography>
					</Stack>
				) : null}
			</Box>
			<Box sx={{ width: 300 }}>
				<ContainedLoadingButton
					loading={isCreatingRule}
					disabled={isLoading}
					onClick={onSubmitClicked}
					color={submitErrors && 'error.main'}
				>
					{t('common:buttons.submit')}
				</ContainedLoadingButton>
			</Box>
		</Stack>
	);
};

SubmitRow.propTypes = {
	t: PropTypes.func,
	ruleConfiguration: PropTypes.object,
	onClickSubmit: PropTypes.func,
	isCreatingRule: PropTypes.bool,
	creationSuccess: PropTypes.bool,
	creationError: PropTypes.string,
	submitErrors: PropTypes.array,
	setSubmitErrors: PropTypes.func,
	isLoading: PropTypes.bool
};

export default withTranslation(['common', 'rules'])(SubmitRow);
