import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)({
	display: 'flex',
	flexDirection: 'column',
	width: '900px'
});

export const Submit = (props) => {
	const { t, onSubmit, onCancel, isPreviousStageCompleted } = props;

	return (
		<StyledPaper>
			<Stack direction='row' spacing={2} justifyContent='flex-end' p={2}>
				<Button variant='outlined' onClick={onCancel}>
					{t('buttons.cancel')}
				</Button>
				<Button
					variant='contained'
					disabled={!isPreviousStageCompleted}
					onClick={onSubmit}
				>
					{t('buttons.submit')}
				</Button>
			</Stack>
		</StyledPaper>
	);
};

Submit.propTypes = {
	t: PropTypes.func,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	isPreviousStageCompleted: PropTypes.bool
};

export default withTranslation('common')(Submit);
