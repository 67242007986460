import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOpportunities } from '../../../containers/Representments/opportunitiesSlice';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import Restricted from '../../../permissionProvider/Restricted';
import RestrictedFallback from '../../../components/shared/RestrictedFallback';
import RepresentmentFilters from '../../../components/Representments/RepresentmentFilters';
import CurrentInventoryTable from '../../../components/Representments/Opportunities/CurrentInventoryTable';

import Container from '@mui/material/Container';
import SubtextError from '../../../components/errors/SubtextError';

const DEFAULT_DAYS_BACK = 7;

const StyledContainer = styled(Container)({
	display: 'flex',
	flexFlow: 'column',
	width: '100%',
	height: '100%'
});

export const OpportunitiesPage = (props) => {
	const { t, isLoading, hasBeenLoaded, markNotChallengedError, getOpportunities } = props;

	return (
		<StyledContainer maxWidth={false}>
			<Restricted
				to='representments.newAndInProgress.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />
				}
			>
				<RepresentmentFilters
					isLoading={isLoading}
					hasBeenLoaded={hasBeenLoaded}
					dataGetterFunction={getOpportunities}
					defaultDaysBack={DEFAULT_DAYS_BACK}
				/>
				{ markNotChallengedError ? (
					<SubtextError errorText={t('representments:failedToMarkNotChallenged')} />
				) : null}
				<CurrentInventoryTable />
			</Restricted>
		</StyledContainer>
	);
};

OpportunitiesPage.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	getOpportunities: PropTypes.func,
	markNotChallengedError: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.opportunitiesSlice.opportunities.isLoading,
		hasBeenLoaded: state.opportunitiesSlice.opportunities.hasBeenLoaded,
		markNotChallengedError: state.opportunitiesSlice.markNotChallenged.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getOpportunities: (payload) => {
			dispatch(getOpportunities(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['common', 'representments'])(OpportunitiesPage));
