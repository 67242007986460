import React from 'react';
import PropTypes from 'prop-types';

import LoadingButton from '@mui/lab/LoadingButton';

const ContainedLoadingButton = (props) => {
	const { bgcolor, children, color, disabled, loading, onClick, sx } = props;

	return (
		<LoadingButton
			sx={{ bgcolor, color, ...sx }}
			disabled={disabled}
			loading={loading}
			onClick={onClick}
			fullWidth
			variant='contained'
		>
			{children}
		</LoadingButton>
	);
};

ContainedLoadingButton.propTypes = {
	bgcolor: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	sx: PropTypes.object
};

export default ContainedLoadingButton;
