import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import My360Header from '../../components/my360/Header';
import NotificationRuleReportFilters from '../../components/reports/notificationRuleReport/NotificationRuleReportFilters';
import NotificationRuleReportTable from '../../components/reports/notificationRuleReport/NotificationRuleReportTable';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Stack from '@mui/material/Stack';

const NotificationRuleReportPage = ({ t }) => {
	return (
		<Stack spacing={2} p={2}>
			<Restricted
				to='reports.notificationRule.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />
				}
			>
				<My360Header label={t('reports:notificationRuleReport.ruleResults')} />
				<NotificationRuleReportFilters />
				<NotificationRuleReportTable />
			</Restricted>
		</Stack>
	);
};

NotificationRuleReportPage.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['reports', 'common'])(NotificationRuleReportPage);
