import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import getSubmitErrors from '../../shared/validators/getSubmitErrors';
import ContainedLoadingButton from '../../../buttons/ContainedLoadingButton';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const SubmitRow = (props) => {
	const {
		t,
		ruleConfiguration,
		onClickSubmit,
		isUpdatingRule,
		updateError,
		submitErrors,
		setSubmitErrors
	} = props;

	const onUpdateClicked = () => {
		const submitErrors = getSubmitErrors(ruleConfiguration, t);

		if (submitErrors.length > 0) {
			setSubmitErrors(submitErrors);
		} else {
			setSubmitErrors();
			onClickSubmit();
		}
	};

	return (
		<Stack
			direction='row'
			justifyContent='flex-end'
			alignItems='center'
			p={1}
			sx={{ maxWidth: '900px', width: '100%',	alignSelf: 'center' }}
		>
			<Box sx={{ flex: 1 }}>
				{updateError ? (
					<Stack>
						<Typography color='error' sx={{ fontWeight: 600 }}>
							{t('rules:submitRow.failureUpdateMessage')}
						</Typography>
						<Typography variant='caption'>{updateError}</Typography>
					</Stack>
				) : null}
			</Box>
			<Box sx={{ width: 300 }}>
				<ContainedLoadingButton
					loading={isUpdatingRule}
					onClick={onUpdateClicked}
					color={submitErrors && 'error.main'}
				>
					{t('common:buttons.update')}
				</ContainedLoadingButton>
			</Box>
		</Stack>
	);
};

SubmitRow.propTypes = {
	t: PropTypes.func,
	ruleConfiguration: PropTypes.object,
	onClickSubmit: PropTypes.func,
	isUpdatingRule: PropTypes.bool,
	updateError: PropTypes.string,
	submitErrors: PropTypes.array,
	setSubmitErrors: PropTypes.func
};

export default withTranslation(['common', 'rules'])(SubmitRow);
