import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import My360Header from '../../components/my360/Header';
import NotificationListFilters from '../../components/notifications/NotificationListFilters';
import NotificationsList from '../../components/notifications/NotificationList';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Stack from '@mui/material/Stack';

const Notifications = ({ t }) => {
	return (
		<Stack p={1}>
			<Restricted
				to='notifications.notificationsList.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />
				}
			>
				<My360Header label={t('notifications:notifications')} />
				<NotificationListFilters />
				<NotificationsList />
			</Restricted>
		</Stack>
	);
};

Notifications.propTypes = {
	t: PropTypes.func
};

export default (withTranslation(['notifications', 'common'])(Notifications));
