import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import UsersManager from '../../components/Users/UsersManager';
import InviteUserModal from '../../components/modals/InviteUserModal';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Container from '@mui/material/Container';

const StyledContainer = styled(Container)({
	display: 'flex',
	flexFlow: 'column'
});

export const Users = (props) => {
	const { t } = props;

	return (
		<StyledContainer maxWidth={false}>
			<Restricted
				to='users.usersList.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('error.notAllowed')} />
				}
			>
				<UsersManager />
				<InviteUserModal />
			</Restricted>
		</StyledContainer>
	);
};

Users.propTypes = {
	t: PropTypes.func
};

export default withTranslation('common')(Users);
